window.addEventListener("load", function () {
    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#007bff"
            },
            "button": {
                "background": "#ffc107"
            }
        },
        "theme": "classic",
        "content": {
            "message": "Wir verwenden Cookies. Mit der Nutzung unserer Website und Dienste akzeptieren Sie unsere Cookie- und Datenschutzrichtlinien",
            "dismiss": "OK",
            "link": "Mehr erfahren",
            "href": "privacy.html"
        }
    })
});
window.addEventListener("load", function () {
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = 'https://connect.facebook.net/de_DE/sdk.js#xfbml=1&version=v2.11';
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
});
// Remove or add 'collapse' class when window width is above or below 992px
if ($(window).width() > 992) {
    $('#quickInfoContent').removeClass('collapse');
}
$(window).resize(function () {
    if ($(window).width() < 992) {
        $('#quickInfoContent').addClass('collapse');
    } else {
        $('#quickInfoContent').removeClass('collapse');
    }
});

// Sticky Navigation on scroll
$(document).scroll(function () {
    var scroll = $(this).scrollTop();
    var topDist = $("#mainNav").position();
    // If window width is below 992 then attach it 
    if ($(window).width() < 992) {
        if (scroll > topDist.top) {
            $('#mainNav').css({ "position": "fixed", "top": "0" });
            $('header').css({ "margin-top": "3.5rem" });
            console.log('scroll_att:' + scroll);
            console.log('topDist_att:' + topDist.top);
        } else {
            $('#mainNav').css({ "position": "static", "top": "auto" });
            $('header').css({ "margin-top": "0" });
            console.log('scroll:' + scroll);
            console.log('topDist:' + topDist.top);
        }
    } else {
        if (scroll > $('#quickInfo').height()) {
            $('#mainNav').css({ "position": "fixed", "top": "0" });
            $('header').css({ "margin-top": "3.5rem" });
            console.log('scroll_att:' + scroll);
            console.log('topDist_att:' + topDist.top);
        } else {
            $('#mainNav').css({ "position": "static", "top": "auto" });
            $('header').css({ "margin-top": "0" });
            console.log('scroll:' + scroll);
            console.log('topDist:' + topDist.top);
        }
    }
});

// Search function
'use strict';


// search & highlight

; (function ($, window, document, undefined) {
    var $container = $('.search');
    if (!$container.length) return true;

    var $input = $container.find('input'),
        $notfound = $container.find('.search__notfound'),
        $items = $container.find('> ul > li'),
        $item = $(),
        itemsIndexed = [];

    $items.each(function () {
        itemsIndexed.push($(this).text().replace(/\s{2,}/g, ' ').toLowerCase());
    });

    $input.on('keyup', function (e) {
        if (e.keyCode == 13) // enter
        {
            $input.trigger('blur');
            return true;
        }

        $items.each(function () {
            $item = $(this);
            $item.html($item.html().replace(/<span class="highlight">([^<]+)<\/span>/gi, '$1'));
        });

        var searchVal = $.trim($input.val()).toLowerCase();
        if (searchVal.length) {
            for (var i in itemsIndexed) {
                $item = $items.eq(i);
                if (itemsIndexed[i].indexOf(searchVal) != -1)
                    $item.removeClass('is-hidden').html($item.html().replace(new RegExp(searchVal + '(?!([^<]+)?>)', 'gi'), '<span class="highlight">$&</span>'));
                else
                    $item.addClass('is-hidden');
            }
        }
        else $items.removeClass('is-hidden');

        $notfound.toggleClass('is-visible', $items.not('.is-hidden').length == 0);
    });
})(jQuery, window, document);


// toggling items on title press

; (function ($, window, document, undefined) {
    $(document).on('click', '.search h2 a', function (e) {
        e.preventDefault();
        $(this).parents('li').toggleClass('is-active');
    });
})(jQuery, window, document);


// auto-show item content when show results reduces to single

; (function ($, window, document, undefined) {
    var $container = $('.search');
    if (!$container.length) return true;

    var $input = $container.find('input'),
        $items = $container.find('> ul > li'),
        $item = $();

    $input.on('keyup', function () {
        $item = $items.not('.is-hidden');
        if ($item.length == 1)
            $item.addClass('js--autoshown is-active');
        else
            $items.filter('.js--autoshown').removeClass('js--autoshown is-active');
    });
})(jQuery, window, document);